<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="11" class="mx-auto">
        <v-row>
          <v-col cols="6" class="text-left">
            <h5>
              Approve claim unique ID:
              <strong>{{ claim.unique_id }}</strong>
            </h5>
          </v-col>
          <v-col cols="6" class="text-right">
            <router-link
              :to="{
                name: 'list-approve-claims',
                params: { batch_id: batchID },
              }"
            >
              <small class="font-weight-bold">Back to claims</small>
            </router-link>
          </v-col>
          <v-col cols="6" class="text-left">
            <h5 class="font-weight-bold">
              Batch name: {{ getBatchByID(batchID).name }} ({{
                getBatchByID(batchID).batch_id
              }})
            </h5>
          </v-col>
        </v-row>

        <!-- claim details below -->
        <v-col cols="11" class="mx-auto p-0">
          <v-row>
            <v-col cols="7" class="p-0 mr-auto">
              <v-card flat class="mt-12 mb-12">
                <v-row justify="start" align="center">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase white--text"
                      >Patient's details</small
                    >
                  </v-col>
                </v-row>
                <!-- biodata details -->
                <v-row justify="start" align="stretch">
                  <v-col cols="8">
                    <v-row
                      v-for="(detail, index) in biodata.details"
                      :key="index"
                      justify="start"
                      align="start"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="grey darken-2">
                        <small class="text-uppercase white--text">{{
                          detail.name
                        }}</small>
                      </v-col>
                      <v-col cols="8" class="overflow-auto">
                        <small class="text-capitalize">{{
                          detail.value
                        }}</small>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4" class="px-0">
                    <v-container
                      fluid
                      fill-height
                      class="grey darken-8 position-relative"
                    ></v-container>
                  </v-col>
                </v-row>
                <!-- presentation and examinations -->
                <v-row justify="start" align="center">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase m-0 white--text"
                      >presenting complaints</small
                    >
                  </v-col>
                  <v-col cols="12" class="mt-4 p-0">
                    <div class="border border-secondary p-2">
                      {{ claim.patient_complaints }}
                    </div>
                  </v-col>
                  <v-col cols="12" class="green darken-2 mt-2">
                    <small class="text-uppercase m-0 white--text"
                      >examination findings</small
                    >
                  </v-col>
                  <v-col cols="12" class="mt-4 p-0">
                    <div class="border border-secondary p-2">
                      {{ claim.examination_findings }}
                    </div>
                  </v-col>
                </v-row>
                <!-- diagnosis (out patient consultants, management, family planning) -->
                <v-row justify="start" align="center" class="mt-4 mb-2">
                  <v-col cols="12" class="green darken-2 mb-2">
                    <small class="text-uppercase m-0 white--text"
                      >diagnosis</small
                    >
                  </v-col>
                  <v-col cols="12" class="grey darken-1">
                    <small class="text-uppercase m-0 white--text"
                      >out patient consultation</small
                    >
                  </v-col>
                  <v-col cols="12" class="mt-4 p-0">
                    <div class="border border-secondary p-3">
                      {{ claim.out_patient_consultation }}
                    </div>
                  </v-col>
                  <v-col cols="12" class="grey darken-1 mt-2">
                    <small class="text-uppercase m-0 white--text"
                      >management</small
                    >
                  </v-col>
                  <v-col cols="12" class="mt-4 p-0">
                    <div class="border border-secondary p-3">
                      {{ claim.management }}
                    </div>
                  </v-col>
                  <v-col cols="12" class="grey darken-1 mt-2">
                    <small class="text-uppercase m-0 white--text"
                      >family planning</small
                    >
                  </v-col>
                  <v-col cols="12" class="mt-4 p-0">
                    <div class="border border-secondary p-3">
                      {{ claim.family_planning }}
                    </div>
                  </v-col>
                </v-row>
                <!-- drugs -->
                <v-row justify="start" align="center">
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase m-0 white--text"
                      >drugs given</small
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8" class="mr-auto p-0">
                        <div class="border border-secondary p-3">
                          {{ claim.drugs_given }}
                        </div>
                      </v-col>
                      <v-col cols="4" class="p-0">
                        <div class="border border-secondary p-3">#500</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- care giver details -->
                <v-row>
                  <v-col cols="12" class="green darken-2">
                    <small class="text-uppercase m-0 white--text"
                      >care giver's detail</small
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-row
                      v-for="(detail, index) in biodata.careGiver"
                      :key="index"
                      justify="start"
                      align="start"
                      class="border border-secondary rounded-0"
                    >
                      <v-col cols="4" class="grey darken-2">
                        <small class="text-uppercase white--text">{{
                          detail.name
                        }}</small>
                      </v-col>
                      <v-col cols="8">
                        <small class="text-capitalize">{{
                          detail.value
                        }}</small>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4" class="px-0">
                    <v-container
                      fluid
                      fill-height
                      class="grey darken-8"
                    ></v-container>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4" class="text-left">
              <v-form @submit.prevent="handleSubmission">
                <small class="font-weight-bold text-uppercase"
                  >amount to be claimed by provider</small
                >
                <br />
                <v-text-field
                  label
                  v-model="claim.amount_claimed"
                  type="number"
                  outlined
                  suffix="(NGN)"
                ></v-text-field>
                <small class="font-weight-bold text-uppercase"
                  >amount to be paid</small
                >
                <br />
                <v-text-field
                  label
                  v-model="claim.amount_to_pay"
                  type="number"
                  outlined
                  :messages="`AMOUNT DENIED   ${(claim.amount_denied =
                    claim.amount_claimed - claim.amount_to_pay)}`"
                  suffix="(NGN)"
                ></v-text-field>
                <small class="font-weight-bold text-capitalize"
                  >state reason</small
                >
                <br />
                <v-textarea v-model="claim.reason" label outlined></v-textarea>
                <v-row justify="center" v-if="isLoading">
                  <v-col class="text-center">
                    <v-progress-circular
                      :size="30"
                      :width="3"
                      color="green darken-4"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row justify="start" v-else>
                  <v-col cols="5">
                    <v-btn
                      type="submit"
                      :disabled="claim.vetted"
                      color="green darken-4"
                      class="white--text px-7"
                      >Approve</v-btn
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-btn
                      color="red darken-4"
                      class="white--text"
                      @click="handleDisapprove"
                      >Disapprove</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <!-- approval warning modal -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          <small>Attention!</small>
        </v-card-title>

        <v-card-text>{{ message }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class="text-capitalize"
            text
            @click="dialog = false"
          >
            <strong>Close</strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end logout modal -->
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      claim: null,
      dialog: false,
      message: "",
    };
  },
  computed: {
    ...mapGetters({
      getBatchByID: "claimModule/getBatchByID",
      isLoading: "claimModule/isLoading",
    }),
    batchID() {
      return this.$route.params.batch_id;
    },
    claimID() {
      return this.$route.params.claim_id;
    },
    biodata() {
      const self = this;
      return {
        careGiver: [
          {
            name: "surname",
            value: self.claim.caregiver_surname,
          },
          {
            name: "first name",
            value: self.claim.caregiver_firstname,
          },
          {
            name: "date",
            value: self.claim.caregiver_date_uploaded,
          },
          {
            name: "health facility",
            value: self.claim.caregiver_health_facility,
          },
        ],
        details: [
          {
            name: "surname",
            value: self.claim.last_name,
          },
          {
            name: "first name",
            value: self.claim.first_name,
          },
          {
            name: "date of birth",
            value: self.claim.date_of_birth,
          },
          {
            name: "identification number",
            value: self.claim.unique_id,
          },
          {
            name: "patient telephone",
            value: self.claim.phone_number,
          },
          {
            name: "guardian name",
            value: self.claim.guardian_name,
          },
          {
            name: "gender",
            value: self.claim.gender,
          },
          {
            name: "address",
            value: self.claim.address,
          },
        ],
      };
    },
  },
  async created() {
    const claim_id = Number(this.claimID);
    this.claim = this.getBatchByID(this.batchID).claims.find(
      (claim) => claim.id === claim_id
    );
  },
  methods: {
    ...mapActions({
      updateClaim: "claimModule/updateClaim",
      fetchClaim: "claimModule/fetchClaim",
    }),
    async handleClaimUpdate(claim) {
      const self = this;
      this.updateClaim({ data: claim }).then(function (res) {
        if (res["status"] === 200) {
          self.$router.push({
            name: "list-approve-claims",
            batch_id: self.$route.params.batch_id,
          });
        }
      });
    },
    async handleSubmission() {
      if (this.claim.reason === "" || this.claim.reason === null) {
        this.message = "Please provide a reason for approving this claim.";
        this.dialog = true;
        return;
      }
      this.claim.vetted = true;
      this.handleClaimUpdate(this.claim);
    },
    async handleDisapprove() {
      if (this.claim.reason === "" || this.claim.reason === null) {
        this.message =
          "Please provide a reason for disapproving this claim, ensure you go through the claim properly before dissapproval.";
        this.dialog = true;
        return;
      }
      this.claim.vetted = false;
      this.handleClaimUpdate(this.claim);
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
